$breakpoints: (
  xl: 1441px,
  lg: 1200px,
  md: 1024px,
  sm: 767px,
  xs: 425px,
  xxs: 376px,
);

@mixin mq($size) {
  $breakpoint: map-get($breakpoints, $size);
  @media only screen and (max-width: $breakpoint) {
    @content;
  }
}
@mixin section($padding) {
  padding: $padding;
}
@mixin tagParts($borderColor, $bgColor, $fontColor) {
  border-radius: 40px;
  -webkit-border-radius: 40px;
  border: 2px solid $borderColor;
  background-color: $bgColor;
  padding: 8px 0;
  font-size: 26px;
  color: $fontColor;
  font-weight: bold;
  width: 266px;
  margin-bottom: 35px;
  text-align: center;
  @include mq(sm) {
    font-size: 22px;
  }
}

@mixin boldCenter($size, $marginBottom, $position) {
  font-size: $size;
  font-weight: bold;
  margin-bottom: $marginBottom;
  text-align: $position;
}

@mixin retina {
  @media
    (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) { 
      @content;
    }
}