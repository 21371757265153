@use 'setting' as s;
@use 'sass:math';

@font-face {
    font-family: 'Inter';
    font-weight: normal;
    src: local('Inter'), url(../../../public/fonts/Inter-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Inter';
    font-weight: bold;
    src: local('Inter'), url(../../../public/fonts/Inter-Bold.ttf) format('truetype');
}
//snippet
.aaa {
    background-color: s.$background-light-gray;
    margin: 0 math.div(22.4, 2);
    @include s.mq(md) {

    }
}
h1, h2, h3 {
    margin-block-start: 0;
    margin-block-end: 0;
}
body {
    font-family: Inter, sans-serif;
}
.to_top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    @include s.mq(md) {
        display: none;
    }
}

.cta-button {
    text-align: center;
    width: fit-content;
    margin: 0 auto;
    a {
        display: block;
    }
    &-hero {
        @extend .cta-button;
        text-align: left;
    }
    &-sp {
        display: none;
    }
    @include s.mq(xs) {
        display: none;
        &-sp {
            display: block;
            text-align: center;
            width: fit-content;
            margin: 0 auto;
            width: 100%;
            a {
                display: block;
                img {
                    object-fit: contain;
                    width: 100%;
                }
            }
        }
    }
}
.hero {
    width: 100%;
    min-height: 980px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../../public/img/hero.png");
    position: relative;
    z-index: 0;
    background-position: 45% 0;
    background-color: s.$header-black;
    @include s.mq(sm) {
        margin-top: -80px;
        background-image: unset;
        background-color: s.$header-black;
        background-position: unset;
    }

    &-img-sp {
        display: none;
        @include s.mq(sm) {
            display: block;
            width: 100%;
            margin-top: 150px;
            position: relative;
            &-gradation {
                width: 100%;
                background-image: linear-gradient(transparent 0, s.$header-black 60%);
                height: 70px;
                position: absolute;
                bottom: 0;
                left: 0;
            }
            img {
                width: 100%;
                object-fit: cover;
            }
        }
    }
    &-align {
        &-right {
            display: flex;
            justify-content: flex-end;
            @include s.mq(sm) {
                display: block;
                position: absolute;
                left: 5%;
                right: 5%;
                bottom: 200px;
            }
            @include s.mq(xxs) {
                display: block;
                position: absolute;
                left: 5%;
                right: 5%;
                bottom: 340px;
            }
        }
        &-left {
            margin-top: 80px;
            margin-left: 8%;
            &-kv-icon {
                width: 420px;
                @include s.mq(sm) {
                    display: none;
                }
                img {
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
    &-wrapper {
        color: #fff;
        font-weight: bold;
        max-width: 547px;
        margin-right: 10%;
        padding-top: calc(40vh - 184px);
        @include s.mq(sm) {
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
            padding-top: 180px;
        }
        @include s.mq(md) {    
            padding-top: calc(30vh - 184px);
            margin-right: 5%;
        }
        &-big {
            font-size: 70px;
            line-height: 1;
            margin-bottom: 10px;
            &-ni {
                font-size: 50px;
            }
            &-wo {
                font-size: 50px;
            }
            @include s.mq(sm) {
                font-size: 54px;
                &-ni {
                    font-size: 44px;
                }
                &-wo {
                    font-size: 44px;
                }
            }
        }
        &-small {
            font-size: 20px;
            margin-bottom: 60px;
            &-pc {
                display: inline-block;
            }
            &-sp {
                display: none;
            }
            @include s.mq(sm) {
                font-size: 18px;
                margin-bottom: 15px;
                &-pc {
                    display: none;
                }
                &-sp {
                    display: inline-block;
                }
            }
        }
    }
}
.section  {
    @include s.section(150px 15%);
    &-form {
        @include s.section(150px 15%);
    }
    &-service {
        @include s.section(150px 15% 0);
    }
    &-npo {
        @include s.section(150px 0 100px);
    }
    &-smallBottom {
        @include s.section(150px 15% 60px);
    }
    &-bg-gray {
        background-color: s.$background-light-gray;
    }
    &-bg-light-gray {
        background-color: s.$background-lighter-gray;
    }
    &-bg-img {
        padding: 120px 15%;
        background-image: url("../../../public/img/vision_bg.png");
        background-size: cover;
        background-repeat: no-repeat;
    }
    &-biggerBottom {
        @include s.section(150px 15%);
    }
    &-cta {
        @include s.section(110px 15% 190px);
    }
    &-value {
        height: 960px;
        background-image: url("../../../public/img/newValueBg.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top left;
        margin-top: -40px;
        
    }
    
    @include s.mq(lg) {
        &-service {
            @include s.section(70px 7% 0);
        }
    }
    @include s.mq(sm) {
        @include s.section(70px 5%);
        &-form {
            @include s.section(70px 0);
        }
        &-service {
            @include s.section(70px 5% 0);
        }
        &-npo {
            @include s.section(70px 0 100px);
        }
        &-smallBottom {
            @include s.section(70px 5% 60px);
        }
        &-bg-gray {
            background-color: s.$background-light-gray;
        }
        &-bg-light-gray {
            background-color: s.$background-lighter-gray;
        }
        &-bg-img {
            padding: 60px 5%;
            background-image: url("../../../public/img/vision_bg.png");
            background-size: cover;
            background-repeat: no-repeat;
        }
        &-biggerBottom {
            @include s.section(70px 5%);
        }
        &-cta {
            @include s.section(0px 5% 150px);
        }
        &-value {
            height: 960px;
            background-image: url("../../../public/img/newValueBg.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 25% 50%;
            margin-top: -40px;
            
        }
    }
}
.center { 
    text-align: center;
    margin: 0 auto;
 }
.heading {
    @include s.boldCenter(40px, 80px, center);
    &-flow {
        @include s.boldCenter(40px, 80px, center);
    }
    &-form {
        @include s.boldCenter(40px, 40px, center);
    }
    &-manifesto {
        @include s.boldCenter(40px, 10px, center);
        line-height: 3rem;
        padding-top: 10rem;
    }
    &-cta {
        @include s.boldCenter(40px, 40px, center);
        .accent {
            color: s.$emphasis;
            &-big {
                font-size: 50px;
            }
        }
    }
    &-vision {
        color: #fff;
        @include s.boldCenter(40px, 30px, center);
    }
    &-diff {
        line-height: 1.2;
        @include s.boldCenter(40px, 40px, left);
    }
    &-tag-parts {
        @include s.tagParts(s.$accent-color, #fff, s.$accent-color);
        &-fill {
            @include s.tagParts(s.$accent-color, s.$accent-color, #fff);
        }
    }
    @include s.mq(sm) {
        @include s.boldCenter(34px, 40px, center);
        &-form {
            @include s.boldCenter(30px, 40px, center);
        }
        &-manifesto {
            @include s.boldCenter(34px, 10px, center);
        }
        &-cta {
            @include s.boldCenter(34px, 40px, center);
            .accent {
                color: s.$emphasis;
                &-big {
                    font-size: 50px;
                }
            }
        }
        &-flow {
            @include s.boldCenter(30px, 30px, center);
        }
        &-vision {
            color: #fff;
            @include s.boldCenter(34px, 30px, center);
        }
        &-diff {
            line-height: 1.2;
            @include s.boldCenter(34px, 40px, left);
        }
        &-tag-parts {
            @include s.tagParts(s.$accent-color, #fff, s.$accent-color);
            &-fill {
                @include s.tagParts(s.$accent-color, s.$accent-color, #fff);
            }
        }
        &-sp-break {
            display: block;
        }
    }
    @include s.mq(xs) {
        &-cta {
            @include s.boldCenter(24px, 30px, center);
            .accent {
                color: s.$emphasis;
                &-big {
                    font-size: 28px;
                }
            }
        }
    }
}
.sub-heading {
    @include s.boldCenter(40px, 50px, center);
    &-biggest {
        font-size: 73px;
        font-weight: bold;
        color: s.$accent-color;
    }
    &-cta {
        @include s.boldCenter(27px, 15px, center);
        &-comp {
            text-align: center;
            margin-bottom: 20px;
            &-over {
                font-size: 24px;
            }
            &-under {
                font-size: 34px;
                font-weight: bold;
            }
        }
        .accent {
            &-border {
                &-big {
                    font-size: 34px;
                    border-bottom: 4px solid s.$accent-color;
                }
            }
        }
    }
    @include s.mq(sm) {
        @include s.boldCenter(34px, 50px, center);
        &-sp-break {
            display: block;
        }
        &-cta {
            line-height: 2;
            @include s.boldCenter(34px, 15px, center);
            &-comp {
                text-align: center;
                margin-bottom: 20px;
                &-over {
                    font-size: 16px;
                    margin-bottom: 10px;
                }
                &-under {
                    font-size: 30px;
                    font-weight: bold;
                }
            }
            .accent {
                &-border {
                    &-big {
                        font-size: 30px;
                        border-bottom: 4px solid s.$accent-color;
                    }
                }
            }
        }
    }
    @include s.mq(xs) {
        &-cta {
            line-height: 2;
            @include s.boldCenter(24px, 15px, center);
            .accent {
                &-border {
                    &-big {
                        font-size: 28px;
                        border-bottom: 4px solid s.$accent-color;
                    }
                }
            }
        }
    }
}
#scroll-trigger {
    width: 100%;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
}
.header {
    height: 40px;
    position: fixed;
    top: 0;
    left: 0;
    padding: 10px 0 20px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    z-index: 9999;
    width: 100%;
    &-bg {
        background-color: s.$header-black;
        &-sp {
            @include s.mq(md) {
                background-color: s.$header-black;
            }
        }
    }
    &-wrapper {
        .logo {
            height: 34px;
            margin-left: 30px;
            @include s.mq(md) {
                height: 25px;
                margin-left: 20px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &-menu {
            &-sp {
                &-list {
                    transition-duration: .3s;
                    ul {
                        display: none;
                    }
                }
                @include s.mq(md) {
                    position: relative;/*ボタン内側の基点となるためrelativeを指定*/
                    background:s.$header-black;
                    cursor: pointer;
                    width: 50px;
                    height: 50px;
                    border-radius: 5px;
                    padding-right: 15px;
            
                    &-list {
                        &.active {
                            display: block;
                            position: fixed;
                            background-color: s.$header-black;
                            top: 0;
                            left: 0;
                            height: 100vh;
                            width: 100%;
                            z-index: 999;
                            transition-duration: .3s;
                            ul {
                                display: block;
                                margin: 80px auto 0;
                                padding-inline-start: 0px!important;
                                li {
                                    font-size: 24px;
                                    border-bottom: 1px solid #fff;
                                    text-align: center;
                                    padding: 20px 0;
                                    list-style: none;
                                    a {
                                        color: #fff;
                                        font-weight: bold;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                    span{
                        display: inline-block;
                        transition: all .4s;/*アニメーションの設定*/
                        position: absolute;
                        left: 14px;
                        height: 3px;
                        border-radius: 2px;
                        background: #fff;
                        width: 45%;
                    }
                      
                    span:nth-of-type(1) {
                        top:15px; 
                    }
                      
                    span:nth-of-type(2) {
                        top:23px;
                    }
                    
                    span:nth-of-type(3) {
                        top:31px;
                    }
                    
                    /*activeクラスが付与されると線が回転して×に*/
                    
                    &.active span:nth-of-type(1) {
                        top: 18px;
                        left: 18px;
                        transform: translateY(6px) rotate(-45deg);
                        width: 30%;
                    }
                    
                    &.active span:nth-of-type(2) {
                    opacity: 0;/*真ん中の線は透過*/
                    }
                    
                    &.active span:nth-of-type(3){
                        top: 30px;
                        left: 18px;
                        transform: translateY(-6px) rotate(45deg);
                        width: 30%;
                    }
                }
            }
            ul {
            @include s.mq(md) {
                display: none;
            }
            display: flex;
            gap: 0 clamp(1.25rem, -7.959rem + 14.39vw, 5rem);
            margin-right: 80px;
            li {
                list-style: none;
                a {
                    text-decoration: none;
                    color: #fff;
                }
            }
        }
    }
}
.footer {
    width: 100%;
    min-height: 287px;
    text-align: center;
    color: #fff;
    position: relative;
    background-color: s.$footer-bg-color;
    &-spacing {
        padding: 0 15px;
    }
    &-img {
        padding: 50px 0 30px;
    }
    &-company-name {
        font-size: 20px;
        a {
            color: #fff;
        }
    }
    &-copyright {
        font-size: 10px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 15px;
    }
    @include s.mq(sm) {
        &-img {
            padding: 50px 15% 60px;
            img {
                width: 100%;
                object-fit: contain;
            }
        }
        &-company-name {
            font-size: 16px;
            line-height: 2;
            a {
                color: #fff;
            }
        }
    }
}
.manifesto {
    color: #fff;
    text-align: center;
    position: relative;
    z-index: 1;
    margin-top: -80px;
    font-size: 22px;
    @include s.mq(sm) {
        text-align: left;
    }
    &-wrapper {
        background-image: linear-gradient(transparent 0, s.$header-black 8%);
        line-height: 2.4;
        position: relative;
        padding-bottom: 100px;
        z-index: 2;
        margin-top: -100px;
        &-pc {
            display: inline-block;
        }
        &-sp {
            display: none;
        }
        @include s.mq(sm) {
            background-image: unset;
            background-color: s.$header-black;
            font-size: 16px;
            padding: 0 20px;
            &-pc {
                display: none;
            }
            &-sp {
                display: inline-block;
            }
        }
    }
    .subhead-border {
        font-size: 32px;
        font-weight: bold;
        text-decoration: underline;
        @include s.mq(sm) {
            font-size: 24px;
        }
    }
    &-head {
        font-size: 40px;
        background: s.$header-black;
        font-weight: bold;
        @include s.mq(sm) {
            font-size: 34px;
            text-align: center;
        }
        &-small {
            font-size: 29px;
        background: s.$header-black;
            margin-bottom: 0px;
            @include s.mq(sm) {
                font-size: 16px;
                text-align: center;
                padding-top: 80px;
            }
        }
        &-big {
            font-size: 50px;
            @include s.mq(sm) {
                font-size: 34px;
                text-align: center;
                padding-top: 20px;
            }
            &-border {
                font-size: 50px;
                border-bottom: 3px solid #000;
                color: s.$yellow;
                display: inline-block;
                line-height: 1;
                @include s.mq(sm) {
                    font-size: 34px;
                    text-align: center;
                }
            }
        }
    }
}
.npo-list {
    &-image-area {
        position: relative;
        &-pc {
            display: inline;
        }
        &-sp {
            display: none;
        }
        img {
            width: 100%;
            min-height: 960px;
            overflow: hidden;
            object-fit: cover;
            aspect-ratio: 3/2;
        }
        @include s.mq(sm) {
            &-pc {
                display: none;
            }
            &-sp {
                display: inline;
            }
            font-size: 34px;
            img {
                width: 100%;
                min-height: 380px;
                overflow: hidden;
                object-fit: contain;
                aspect-ratio: unset;
            }
        }
    }
    &-heading {
        position: absolute;
        top: 140px;
        left: 50%;
        font-size: clamp(2.813rem, 1.677rem + 2.37vw, 3.75rem);
        font-weight: bold;
        color: #fff;
        transform: translateX(-50%);
        width: fit-content;
        white-space: nowrap;
        &-sp-break {
            display: none;
        }
        @include s.mq(sm) {
            position: absolute;
            top: 60px;
            left: 50%;
            font-size: clamp(2.438rem, 0.849rem + 6.05vw, 3.75rem);
            font-weight: bold;
            color: #fff;
            transform: translateX(-50%);
            width: 80%;
            text-align: center;
            white-space: unset;
            &-sp-break {
                display: block;
            }
        }
        @include s.mq(xs) {
            width: 90%;
        }
    }
    &-accordion {
        margin: -250px auto 0;
        position: relative;
        z-index: 2;
        max-width: 1440px;
        padding: 0 5%;
        
        @include s.mq(md) {
            padding: 0 3%;
        }
        @include s.mq(sm) {
            padding: 0;
            margin: -50px 5% 0;
        }
    }
}
.triangle {
    width: 100vw;
    height: 134px;
    transform: rotate(180deg);
    clip-path: polygon(50% 0, 115% 100%, 0 78%);/*polygon(50% 0, 100% 100%, 0 100%);*/
    background-color: s.$header-black;
    padding-top: 40px;
}
.contents {
    &-wrapper {
        max-width: s.$max-width;
        margin: s.$margin-center;
    }
    &-bg-colored {
        background-color: s.$background-light-gray;
    }
    .form {
        display: flex;
        flex-direction: column;
        gap: 13px 0;
        margin-bottom: 100px;
        &-attention {
            font-size: 29px;
            font-weight: bold;
            margin-bottom: 30px;
            text-align: center;
        }
        @include s.mq(md) {
            gap: 0;
            margin-bottom: 60px;
            &-attention {
                font-size: 24px;
                margin-bottom: 15px;
            }
        }
        @include s.mq(sm) {
            gap: 0;
            margin-bottom: 60px;
            &-attention {
                font-size: 18px;
                margin-bottom: 15px;
            }
        }
        &-frame {
            width: 100%;
            height: 1200px;
            border-radius: 20px;
            -webkit-border-radius: 20px;
            
            @include s.mq(sm) {
                margin: 0 auto;
                width: 90%;
                display: flex;
            }
        }
        &-note {
            border-radius: 20px;
            -webkit-border-radius: 20px;
            background-color: s.$footer-bg-color;
            color: #fff;
            display: flex;
            justify-content: space-between;
            padding: 30px 60px;
            &-head {
                font-size: 30px;
                font-weight: bold;
                &-big {
                    font-size: 40px;
                    font-weight: bold;
                }
            }
            &-explain {
                margin-top: 10px;
                max-width: 600px;
                font-size: 20px;
                line-height: 1.8;
                &-green {
                    color: s.$accent-color;
                    text-decoration: underline;
                }
            }
            
            @include s.mq(md) {
                flex-direction: column;
                border-radius: 0;
                -webkit-border-radius: 0;
                padding: 30px 20px;
                &-head {
                    font-size: 24px;
                    font-weight: bold;
                    text-align: center;
                    &-big {
                        font-size: 30px;
                        font-weight: bold;
                    }
                    &-sp-break {
                        display: block;
                    }
                }
                &-explain {
                    margin-top: 10px;
                    max-width: 600px;
                    font-size: 16px;
                    line-height: 1.8;
                    &-green {
                        color: s.$accent-color;
                        text-decoration: underline;
                    }
                }
                &-img {
                    height: 136px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }
        &-docs {
            border-radius: 20px;
            gap: 0 20px;
            -webkit-border-radius: 20px;
            background-color: s.$accent-color;
            color: #000;
            display: flex;
            justify-content: space-between;
            padding: 30px clamp(1.25rem, -5.901rem + 11.17vw, 3.75rem);
            position: relative;
            &-head {
                font-size: 30px;
                font-weight: bold;
                &-big {
                    font-size: 40px;
                    font-weight: bold;
                }
            }
            &-explain {
                margin-top: 10px;
                max-width: 530px;
                font-size: 20px;
                font-weight: bold;
                line-height: 1.8;
                &-underline {
                    text-decoration: underline;
                }
            }

            &::before {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                margin-left: -24px;
                border: 24px solid transparent;
                border-top: 24px solid s.$accent-color;
            }
            &-img {
                height: 136px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
            @include s.mq(md) {
                flex-direction: column;
                border-radius: 0;
                -webkit-border-radius: 0;
                padding: 30px 20px;
                &-head {
                    font-size: 24px;
                    font-weight: bold;
                    text-align: center;
                    &-big {
                        font-size: 30px;
                        font-weight: bold;
                    }
                    &-sp-break {
                        display: block;
                    }
                }
                &-explain {
                    margin-top: 10px;
                    max-width: 600px;
                    font-size: 16px;
                    line-height: 1.8;
                    margin-bottom: 10px;
                    &-green {
                        color: s.$accent-color;
                        text-decoration: underline;
                    }
                }
                &-img {
                    height: 136px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                &-sp {
                    display: none;
                }
            }
        }
    }
    .new-value {
        position: relative;
        margin-top: 56px;
        &-flex {
            display: flex;
            color: #000;
            margin-top: 60px;
            @include s.mq(sm) {
                padding: 0 5%;
            }
            &-left { 
                width: 50%;
                @include s.mq(lg) {
                    width: 0;
                }
            }
            &-right {
                padding: 40px 60px;
                text-align: left;
                max-width: 463px;
                border-radius: 20px;
                -webkit-border-radius: 20px;
                font-size: 20px;
                background-color: s.$background-lighter-gray;
                border: 4px solid s.$accent-color;
                
                &-pc {
                    display: inline-block;
                }
                &-sp {
                    display: none;
                }
                @include s.mq(lg) {
                    margin: 0 auto;
                }
                @include s.mq(sm) {
                    padding: 20px;
                    font-size: 16px;
                    max-width: 90%;
                    width: 90%;
                    &-pc {
                        display: none;
                    }
                    &-sp {
                        display: inline-block;
                    }
                }
                &-heading {
                    width: 100%!important;
                    font-weight: bold;
                    font-size: 30px;
                    margin-bottom: 10px;
                    @include s.mq(sm) {
                        font-size: 21px;
                        line-height: 2;
                    }
                    @include s.mq(sm) {
                        font-size: 21px;
                        line-height: 1.4;
                    }
                }

            }
        }
        &-wrapper{
            &-first {
                text-align: center;
                color: #fff;
            }
            &-second {
                text-align: center;
            }
        }
        &-head {
            font-size: 40px;
            font-weight: bold;
            &-pc {
                display: inline-block;
            }
            &-sp {
                display: none;
            }
            &-smaller {
                display: inline-block;
            }
            @include s.mq(sm) {
                font-size: 34px;
                text-align: center;
                &-pc {
                    display: none;
                }
                &-sp {
                    display: inline-block;
                }
                &-smaller {
                    display: block;
                    font-size: 30px;
                    margin-top: 5px;
                }
            }
            &-small {
                font-size: 29px;
                margin-bottom: 20px;
                @include s.mq(sm) {
                    font-size: 16px;
                    text-align: center;
                }
            }
            &-big {
                font-size: 50px;
                @include s.mq(sm) {
                    font-size: 34px;
                    text-align: center;
                }
                &-border {
                    font-size: 50px;
                    border-bottom: 3px solid #000;
                    display: inline-block;
                    line-height: 1;
                    @include s.mq(sm) {
                        font-size: 34px;
                        text-align: center;
                    }
                }
            }
        }
        ul {
            list-style: none;
            width: fit-content;
            margin: 40px auto 0;
            padding-inline-start: 0px!important;
            @include s.mq(sm) {
                margin: 10px auto 0;
            }
            li {
                font-size: 24px;
                padding: 20px 0px;
                width: 100%;
                display: inline-flex;
                gap: 0 20px;
                align-items: center;
                &:not(:last-child) {
                    border-bottom: 1px dashed #000;
                }
                .marker {
                    background: linear-gradient(transparent 30%, s.$yellow 70%);
                }
                @include s.mq(sm) {
                    font-size: 20px;
                    padding-right: 20px;
                }
            }
        }
    }
    .diff {
        &-wrapper {
            display: flex;
            &-left {
                width: 50%;
                height: auto;
                display: flex;
                flex-direction: column;
                img {
                    object-fit: cover;
                    display: block;
                    height: 100%;
                }
            }
            &-right {
                width: 100%;
                padding: 80px clamp(2.5rem, -13.589rem + 25.14vw, 8.125rem);
                font-size: 20px;
                display: flex;
                flex-direction: column;
                &-explain {                
                    line-height: 1.8;
                }
                &-ceo {
                    display: flex;
                    margin-top: 30px;
                    align-items: flex-start;
                    justify-content: space-between;
                    .ceo-title {
                        font-weight: bold;
                    }
                }
            }
            @include s.mq(md) {
                flex-direction: column-reverse;
                &-left {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: column;
                    img {
                        object-fit: cover;
                        display: block;
                        height: 100%;
                    }
                }
                &-right {
                    width: unset;
                    padding: 60px;
                    font-size: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
            
                    &-ceo {
                        display: flex;
                        margin-top: 30px;
                        align-items: flex-start;
                        flex-direction: column;
                        align-items: center;
                        gap: 20px 0;
                        justify-content: space-between;
                        .ceo-title {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
    .vision {
        font-size: 20px;
        &-wrapper {
            padding: 60px 80px;
            background-color: rgba(255, 255, 255, 0.7);
        }
        &-title {
            @include s.boldCenter(40px, 20px, center);
        }
        &-explain {
            line-height: 1.8;
            margin-bottom: 30px;
            font-size: 20px;
        }
        &-sime {
            font-weight: bold;
            text-align: center;
            font-size: 20px;
        }
        @include s.mq(sm) {
            &-wrapper {
                border-radius: 20px;
                -webkit-border-radius: 20px;
                padding: 30px 20px;
                background-color: rgba(255, 255, 255, 0.7);
            }
            &-title {
                @include s.boldCenter(40px, 20px, center);
            }
            &-explain {
                line-height: 1.8;
                margin-bottom: 30px;
                font-size: 20px;
            }
            &-sime {
                font-weight: bold;
                text-align: center;
            }
        }
    }
    .flow {
        &-img {
            object-fit: cover;
            img {
                width: 100%;
            }
        }
        &-requirement {
            border: 1px solid s.$accent-color;
            margin: 80px 0 20px;
            padding: 15px 45px;
            background-color: s.$accent-color;
            font-size: 24px;
            width: fit-content;
        }
        &-explain {
            font-size: 20px;
            &-mark {
                color: s.$emphasis;
                font-weight: bold;
            }
        }
        &-note {
            font-size: 12px;
        }
        &-pc {
            display: block;
        }
        &-sp {
            display: none;
        }
        @include s.mq(sm) {
            &-img {
                object-fit: cover;
                img {
                    width: 100%;
                }
            }
            &-requirement {
                border: 1px solid s.$accent-color;
                margin: 50px auto 20px;
                padding: 15px;
                background-color: s.$accent-color;
                font-size: 24px;
                width: 90%;
                text-align: center;
            }
            &-explain {
                font-size: 16px;
                line-height: 1.8;
                &-mark {
                    color: s.$emphasis;
                    font-weight: bold;
                }
            }
            &-note {
                font-size: 12px;
                margin-top: 20px;
            }
            &-pc {
                display: none;
            }
            &-sp {
                display: block;
            }
            &-process {
                border: 5px solid s.$accent-color;
                padding: 30px;
                background-color: #fff;
                position: relative;
                &:not(:last-child) {
                    margin-bottom: 40px;
                    &::before {
                        content: "";
                        position: absolute;
                        bottom: -62px;
                        left: 50%;
                        margin-left: -24px;
                        border: 24px solid transparent;
                        border-top: 24px solid s.$accent-color;
                    }
                }
                &-wrapper {
                    display: flex;
                    gap: 0 20px;
                    align-items: center;
                    &-num {
                        font-size: 40px;
                        font-weight: bold;
                        font-style: italic;
                        color: s.$border-black-color;
                    }
                    &-title {
                        font-size: 24px;
                        font-weight: bold;
                    }
                    &-note {
                        text-align: center;
                        font-size: 12px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    .service {
        &-right {
            width: 50%;
            @include s.mq(sm) {
                width: 100%;
            }
        }
        &-graph {
            &-img {
                width: 50%;
                @include s.mq(sm) {
                    width: 80%;
                    margin: 0 auto;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: top;
                        text-align: center;
                    }
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: top;
                }
            }
        }
        &-wrapper {
            display: flex;
            gap: 0 30px;
            margin-bottom: 100px;
            @include s.mq(sm) {
                flex-direction: column;
                margin-bottom: 40px;
                img {
                    width: 100%;
                }
            }
        }
        &-list {
            padding-inline-start: 0px!important;
            @include s.mq(sm) {
                margin-top: 40px;
            }
            li {
                list-style: none;
                margin-bottom: 50px;
                position: relative;
                .emphasis {
                    font-weight: bold;
                    background: linear-gradient(transparent 30%, s.$yellow 70%);
                    .small {
                        font-size: 14px;
                    }
                }
                .title-wrapper {
                    display: flex;
                    border-bottom: 1px solid s.$accent-color;
                    align-items: center;
                    margin-bottom: 10px;
                    .title {
                        font-size: 30px;
                        margin-bottom: 5px;
                        font-weight: bold;
                        @include s.mq(sm) {
                            font-size: 20px;
                        }
                    }
                    .num {
                        background-color: s.$accent-color;
                        font-style: italic;
                        font-size: 30px;
                        width: 52px;
                        min-width: 52px;
                        height: 52px;
                        border-radius: 50%;
                        -webkit-border-radius: 50%;
                        text-align: center;
                        top: -5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        position: relative;
                        margin-right: 18px;
                        div {
                            position: absolute;
                            top: 50%;
                            left: 45%;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }
            li .description {
                font-size: 20px;
                line-height: 1.6;
                padding-left: 70px;
                @include s.mq(sm) {
                    font-size: 16px;
                  padding-left: 0px;
                }
                &-small {
                    font-size: 15px;
                    @include s.mq(sm) {
                        font-size: 12px;
                        margin-top: 15px;
                    }
                }
                &-note {
                    border: 1px solid s.$arrow-border-color;
                    padding: 15px;
                    font-size: 18px;
                    margin-top: 20px;
                    @include s.mq(sm) {
                        font-size: 16px;
                    }
                    &-link {
                        font-size: 16px;
                        margin-top: 10px;
                        width: fit-content;
                        display: flex;
                        align-items: center;
                        gap: 0 8px;
                        a {
                            color: s.$border-black-color;
                        }
                        @include s.mq(sm) {
                            font-size: 16px;
                            img {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    .feature {
        margin-bottom: 20px;
        padding: 40px 50px;
        background-color: s.$background-lighter-gray;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        @include s.mq(sm) {
            padding: 40px 20px;
        }
        &-wrapper {
            border-bottom: 3px solid s.$accent-color;
            margin-bottom: 40px;
            position: relative;
            display: flex;
            align-items: baseline;
            gap: 0 10px;
            
            @include s.mq(sm) {
                gap: 0;
                flex-direction: column;
                align-items: center;
                margin-bottom: 20px;
            }
        }
        &-contents {
            display: flex;
            gap: 0 40px;
            div {
                width: 50%;
            }
            @include s.mq(sm) {
                gap: 20px 0;
                flex-direction: column;
                align-items: center;
                div {
                    width: 100%;
                }
            }
        }
        &-img {
            img {
                width: 100%;
                height: auto;
            }
        }
        &-number {
            font-size: 60px;
            font-weight: bold;
            color: s.$accent-color;
            font-style: italic;
        }
        &-title {
            @include s.boldCenter(28px, 10px, center);
            margin-top: 20px;
            
            @include s.mq(sm) {
                @include s.boldCenter(28px, 10px, left);
                margin-top: 10px;
                width: 100%;
            }
        }
        &-description {
            font-size: 20px;
            line-height: 1.8;
            .emphasis {
                font-weight: bold;
                background: linear-gradient(transparent 30%, s.$yellow 70%);
                .small {
                    font-size: 14px;
                }
            }
        }
    }
}
.compare {
    &-feature {
        padding: 40px 20px;
        @include s.mq(sm) {
            padding: 20px 15px 20px 5px;
            text-align: left;
        }
    }
    &-table {
        font-size: clamp(1.125rem, -1.412rem + 3.96vw, 1.375rem);
        font-weight: bold;
        table {
            text-align: center;
            margin: 0 auto 50px;
            border-collapse: collapse;
            th {
                padding: 5px 20px;
            }
            .enterprise {
                background-color: #fff;
                padding: 5px 0;
                min-width: 25%;
            }
            .energy-gift {
                background-color: s.$yellow;
                padding: 5px 0;
                min-width: 25%;
            }
            td {
                width: 25%;
                border-right: 1px dashed s.$border-black-color;
                border-bottom: 1px dashed s.$border-black-color;
            }
            
        }
        @include s.mq(md) {
            font-size: 16px;
            table {
                td {                
                    width: 25%;
                    border-right: 1px dashed s.$border-black-color;
                    border-bottom: 1px dashed s.$border-black-color;
                }
                th {
                    padding: 5px;
                }
                .enterprise {
                    background-color: #fff;
                    padding: 0 5px;
                    min-width: 25%;
                    font-size: 16px;
                }
                .energy-gift {
                    background-color: s.$yellow;
                    padding: 0 5px;
                    min-width: 25%;
                    font-size: 16px;
                }

            }
        }
    }
}
.accordion {
    margin: s.$margin-center 20px;
    &-npo {
        background-color: #fff;
        border-radius: 20px;
        -webkit-border-radius: 20px;
        &-flex {
            &-wrapper {
                display: flex;
                align-items: center;
                @include s.mq(sm) {
                    flex-direction: column;
                    align-items: flex-start;
                }
            }
            &-inner-wrapper {
                display: flex;
                align-items: center;
            }
        }
    }
    &-faq {
        background-color: s.$background-lighter-gray;
        border: 2px solid s.$background-dark-gray;
        border-radius: 20px;
        -webkit-border-radius: 20px;
    }
    &-contents01 {
        color: s.$emphasis;
    }
    &-border01 {
        border: 3px solid s.$emphasis;
    }
    &-svg01 svg {
        fill: s.$emphasis;
    }
    &-contents02 {
        color: s.$orange;
    }
    &-border02 {
        border: 3px solid s.$orange;
    }
    &-svg02 svg {
        fill: s.$orange;
    }
    &-contents03 {
        color: s.$blue;
    }
    &-border03 {
        border: 3px solid s.$blue;
    }
    &-svg03 svg {
        fill: s.$blue;
    }
    &-contents04 {
        color: s.$accent-color;
    }
    &-border04 {
        border: 3px solid s.$accent-color;
    }
    &-svg04 svg {
        fill: s.$accent-color;
    }
    &-contents05 {
        color: s.$dark-yellow;
    }
    &-border05 {
        border: 3px solid s.$yellow;
    }
    &-svg05 svg {
        fill: s.$dark-yellow;
    }
    &-arrow {
        top: 50%;
        position: absolute;
        right: 25px;
        width: 25px;
        height: 25px;
        border-top: 3px solid s.$arrow-border-color;
        border-right: 3px solid s.$arrow-border-color;
        -webkit-transform: translateY(-50%) rotate(45deg);
        transform: translateY(-50%) rotate(45deg);
        transition-duration: .3s;
        &.is-open {
            -webkit-transform: translateY(-50%) rotate(135deg);
            transform: translateY(-50%) rotate(135deg);
        }
    }
    &-npo-wrapper {
        padding: math.div(59px, 2) clamp(1.875rem, 0.807rem + 5.34vw, 4.813rem) math.div(59px, 2) clamp(1.25rem, -6.361rem + 15.88vw, 4.813rem);
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        @include s.mq(sm) {
            padding: math.div(59px, 2) 40px;
        }
        .org-name {
            font-size: clamp(1.25rem, 1.028rem + 0.46vw, 1.375rem);
            padding-left: 25px;
            white-space: nowrap;
            @include s.mq(sm) {
                font-size: clamp(0.813rem, 0.132rem + 2.59vw, 1.375rem);
                padding: 10px 0 0;
            }
        }
        .activity-headline {
            font-size: 28px;
            font-weight: bold;
            padding-left: 25px;
        }
        
        &-contents {
            padding: 0 8%;
            visibility: hidden;
            overflow: hidden;
            height: 0;
            transition: all 0.3s;
            position: relative;
            &.is-open {
                padding: 0 8% 20px;
                visibility: visible;
                height: auto;
                transition: all 0.3s;
            }
            .contents-wrapper {
                display: flex;
                gap: 0 20px;
                font-size: 20px;
                line-height: 1.8;
                div {                
                    max-width: 50%;
                }
                .images {
                    min-width: 310px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 5px 0;
                }
                @include s.mq(sm) {
                    flex-direction: column;
                    gap: 15px 0;
                    div {                
                        max-width: 100%;
                    }
                }
            }
            .activity-wrapper {
                margin: 40px 0 20px;
                .sub-headline {
                    font-size: 28px;
                }
                ul li {
                    font-size: 20px;
                }
            }
            .link-wrapper {
                display: block;
                border: 2px solid s.$border-black-color;
                border-radius: 10px;
                -webkit-border-radius: 10px;
                font-weight: bold;
                font-size: 20px;
                text-align: center;
                margin: 40px 0;
                padding: 15px 0;
                text-decoration: none;
                color: s.$font-black-color;
                width: 100%;
                .link:after {
                    margin: 0 0 0 6px;
                    font-family: "Font Awesome 5 Free";
                    content: '\f35d';
                    font-weight: 900;
                }
                @include s.mq(xs) {
                    width: unset;
                    padding: 30px;
                }

            }
        }
    }
    &-faq-wrapper {
        padding: math.div(59px, 2) 58px math.div(59px, 2) clamp(1.25rem, -1.628rem + 6vw, 3.625rem);
        position: relative;
        cursor: pointer;
        .question-headline {
            font-size: 28px;
            font-weight: bold;
            padding: 0 25px;
            @include s.mq(xs) {
                font-size: 24px;
                padding: 0 45px 0 25px;
            }
        }
        &-headline {
            display: flex;
            gap: 0 10px;
            .qb {
                font-size: 28px;
                font-weight: bold;
                margin-right: 10px;
                color: s.$accent-color;
                @include s.mq(xs) {
                    margin-right: 0;
                }
            }
        }
        &-contents {
            padding: 0 5%;
            visibility: hidden;
            overflow: hidden;
            height: 0;
            transition: all 0.3s;
            position: relative;
            &.is-open {
                padding: 0 5% 20px;
                visibility: visible;
                height: auto;
                transition: all 0.3s;
            }
            .contents-wrapper {
                display: flex;
                gap: 0 10px;
                font-size: 20px;
                line-height: 1.8;
                background-color: #fff;
                padding: 20px 32px 30px;
                align-items: baseline;
                .ab {
                    font-size: 28px;
                    font-weight: bold;
                    margin-right: 10px;
                    color: s.$emphasis;
                }
                .answer {
                    font-size: 20px;
                    width: 100%;
                    a {
                        color: s.$font-black-color;
                    }
                }
            }
        }
        @include s.mq(xs) {
            padding: math.div(59px, 2) 0;
        }
    }
}