
$background-light-gray: #ECECEC;
$background-lighter-gray: #f5f5f5;
$background-dark-gray: #D9D9D9;
$arrow-border-color: #848484;
$border-black-color: #6d6a6a;
$font-black-color: #373737;
$footer-bg-color: #181818;
$header-black: #060504;
$max-width: 1440px;
$margin-center: 0 auto;
$list-color: #80D134;
$accent-color: #80D134;
$emphasis: #F4399E;
$yellow: #FFD80E;
$dark-yellow: #EAC500;
$orange: #FF910F;
$blue: #18CCE5;